footer {
  background-color: var(--secondary);
  color: var(--white);

  address {
    font-style: normal;
  }

  footer-map {
    flex-grow: 1;

    iframe {
      height: 100%;
      width: 100%;
    }
  }
}

footer .columns {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-10);
  justify-content: space-between;
  padding-block: var(--spacing-12);
}

footer :is(a.link) {
  text-decoration: none;
}

footer :is(a.link:hover) {
  color: var(--primary);
  text-decoration: underline;
  text-underline-offset: calc(var(--spacing-1) + var(--spacing-05));
}

footer .bottom {
  border-top: 1px solid var(--primary);
  display: flex;
  flex-wrap: wrap;
  font-size: 0.75rem;
  gap: var(--spacing-8);
  justify-content: space-between;
  padding-block: var(--spacing-10);
}

footer .bottom-column {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-3) var(--spacing-6);
}
