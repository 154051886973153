:root {
  /* --- untinted (almost) grays --- */
  --black: #131415;
  --black-rgb: 19, 20, 21;
  --gray-90: #222325;
  --gray-80: #323233;
  --gray-70: #424344;
  --gray-50: #6a6c6d;
  --gray-40: #aab0b6;
  --gray-20: #eeeeee;
  --gray-10: #f9fafb;
  --white: #ffffff;
  --white-rgb: 255, 255, 255;


  --rgb-white: 255, 255, 255;
  --white: rgb(var(--rgb-white));
  --rgb-pearl: 255, 246, 236;
  --pearl: rgb(var(--rgb-pearl));

  /* --rgb-black: 27, 34, 48; */
  --rgb-black: 0, 0, 0;
  --black: rgb(var(--rgb-black));
  --transparent-black-3: rgba(var(--rgb-black), 0.3);
  --transparent-black: rgba(var(--rgb-black), 0.5);

  --rgb-primary: 255, 91, 82;
  --primary: rgb(var(--rgb-primary));
  --transparent-primary-3: rgba(var(--rgb-primary), 0.3);
  --transparent-primary: rgba(var(--rgb-primary), 0.5);
  --transparent-primary-8: rgba(var(--rgb-primary), 0.8);

  --rgb-secondary: 49, 51, 105;
  --secondary: rgba(var(--rgb-secondary));
  --transparent-secondary-3: rgba(var(--rgb-secondary), 0.3);
  --transparent-secondary: rgba(var(--rgb-secondary), 0.5);
  --transparent-secondary-8: rgba(var(--rgb-secondary), 0.8);

  /* --- green --- */
  --green-100: #11201d;
  --green-90: #002e1f;
  --green-80: #005C42; /* new */
  --green-70: #008a64;
  --green-60: #00bd7e;
  --green-50: #31C996; /* new */
  --green-40: #62D5AF; /* new */
  --green-30: #92E2C7; /* new */
  --green-20: #C3EEE0; /* new */
  --green-10: #f4faf8;

  /* comprehensive greens */
  --darkest-green: var(--green-100);
  --dark-green: var(--green-90);
  --medium-green: var(--green-70);
  --green: var(--green-60);
  --green-rgb: 0, 189, 126;
  --light-green: var(--green-10);

  /* --- red --- */
  --red-100: #241314;
  --red-90: #3b1410;
  --red-70: #730703;
  --red-60: #bf0e08;
  --red-50: #CB3C37;
  --red-40: #D86965;
  --red-30: #E49794;
  --red-20: #F1C4C2;
  --red-10: #fdf2f1;

  /* comprehensive reds */
  --darkest-red: var(--red-100);
  --darker-red: var(--red-90);
  --dark-red: var(--red-70);
  --red: var(--red-60);
  --red-rgb: 191, 14, 8;
  --light-red: var(--red-10);

  /* --- orange --- */
  --orange-100: #272018;
  --orange-70: #563814;
  --orange-60: #df9136;
  --orange-50: #ffa500;
  --orange-50-rgb: 255, 165, 0;
  --orange-10: #fef4e8;
  --orange-05: #fdf7f0;

  /* comprehensive orange */
  --darkest-orange: var(--orange-100);
  --dark-orange: var(--orange-70);
  --orange: var(--orange-60);
  --orange-rgb: 223, 145, 54;
  --light-orange: var(--orange-10);
  --xlight-orange: var(--orange-05);

  /* other orange */
  --bright-orange: #ee6237;

  /* --- yellow --- */
  --yellow-70: #735c0f;
  --yellow-60: #f6e9be;
  --yellow-10: #fffbdd;

  /* comprehensive yellows */
  --darker-yellow: var(--yellow-70);
  --dark-yellow: var(--yellow-60);
  --yellow: var(--yellow-10);

  /* --- purple --- */
  --purple-100: #2e1130;
  --purple-60: #800080;
  --purple-50: #973098;
  --purple-40: #AE60AF;
  --purple-30: #C690C7;
  --purple-20: #DDC0DE;
  --purple-10: #f4f0f6;

  /* comprehensive purple */
  --darkest-purple: var(--purple-100);
  --purple: var(--purple-60);
  --light-purple: var(--purple-10);

  --primary-color: var(--primary);
  --secondary-color: var(--secondary);
  --accent-color: var(--purple-60);

  --bg-color: var(--pearl);
  --bg-color-secondary: var(--gray-10);
  --bg-color-inverted: var(--black);
  --bg-color-selected: color-mix(in srgb, var(--primary-color), transparent 90%);
  --bg-color-gradient: linear-gradient(135deg, var(--blue-20), var(--blue-10), var(--purple-10));
}
