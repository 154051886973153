teacher-item {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);

  img {
    width: 100%;
  }

  h3, p {
    margin: 0;
  }
}
