teacher-list {
  display: grid;
  gap: var(--spacing-8);
  grid-template-columns: repeat(3, 1fr);
  margin-block: 1rem;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}
