pricing-card {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: var(--spacing-4);
  text-align:center;

  &:last-child {
    flex-shrink: 1;
  }

  strong {
    color: var(--primary);
    font-size: 2rem;
  }

  h3 {
    margin: 0;
    text-align: center;
  }

  ul {
    display: flex;
    gap: var(--spacing-6);
    justify-content: space-evenly;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  h4 {
    font-weight: 600;
    margin: 0;
  }

  p {
    margin: 0;
  }
}
