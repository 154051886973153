nav-item {
  display: flex;
  justify-content: center;

  :is(.item, a:visited, a:focus) {
    border-radius: var(--border-radius-medium);
    color: var(--text-color);
    display: flex;
    padding: var(--spacing-2) var(--spacing-3);
    position: relative;
    text-decoration: none;
  }

  :is(.item:hover) {
    background-color: var(--bg-color-selected);
    color: var(--base-color);
  }

  ul {
    backdrop-filter: blur(var(--spacing-8));
    border-radius: var(--border-radius-medium);
    box-shadow: var(--shadow-elevation-low);
    display: none;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: var(--spacing-2);
    position: absolute;
    top: 3.5rem;

    .sub-item a {
      border-radius: var(--border-radius-medium);
      padding: var(--spacing-1) var(--spacing-3);
      text-decoration: none;

      &:hover {
        background-color: var(--bg-color-selected);
      }
    }
  }

  &:hover ul {
    display: flex;
  }
}
