:root {
  --border-radius: 8px;

  --title-font: "Montserrat";
  --title-color: var(--secondary);

  --text-font: "Montserrat";
  --text-color: var(--secondary);
  --text-color-disabled: var(--gray-40);
  --text-color-secondary: var(--slate-70);
  --text-color-inverted: var(--white);

  --link-color: var(--blue-60);
  --link-color-hover: var(--gray-50);

  --page-content-width: 1300px;

  --gap-default: var(--spacing-6);

  --font-weight-normal: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
}
