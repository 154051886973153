.home main {
  row-gap: 0;

  .features {
    gap: var(--spacing-10);
    margin-top: var(--spacing-48);

    ul {
      border: 2px solid var(--primary);
      display: grid;
      gap: var(--spacing-8);
      grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
      list-style-type: none;
      margin: 0;
      padding: var(--spacing-8);

      li p:last-child {
        margin-bottom: 0;
      }

      h3 {
        margin-top: 0;
      }
    }

    @media(max-width: 800px) {
      flex-direction: column;
      margin-top: var(--spacing-16);

      ul {
        grid-template-columns: 1fr;
      }
    }
  }
}

.img-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
