:where(blockquote) {
  --callout-bg-color: var(--bg-color-secondary);
  --callout-border-color: var(--border-color-default);
  --callout-icon-path: url(/images/icons/info.svg);

  background-color: var(--callout-bg-color);
  border-left: 4px solid var(--callout-border-color);
  border-radius: var(--border-radius-medium);
  display: flex;
  flex-direction: column;
  margin-inline: 0;
  padding-inline: var(--spacing-12) var(--spacing-3);
  position: relative;
}

:where(blockquote)::before {
  background: var(--callout-icon-path);
  content: "";
  display: inline-block;
  height: var(--spacing-4);
  left: var(--spacing-4);
  min-width: var(--spacing-4);
  position: absolute;
  top: calc(var(--spacing-4));
  width: var(--spacing-4);
}

:where(blockquote.info) {
  --callout-bg-color: var(--bg-color-info);
  --callout-border-color: var(--text-color-info);
  --callout-icon-path: url(/images/icons/info.svg);
}

:where(blockquote.success) {
  --callout-bg-color: var(--bg-color-success);
  --callout-border-color: var(--text-color-success);
  --callout-icon-path: url(/images/icons/success.svg);
}

:where(blockquote.warning) {
  --callout-bg-color: var(--bg-color-warning);
  --callout-border-color: var(--text-color-warning);
  --callout-icon-path: url(/images/icons/warning.svg);
}

:where(blockquote.error) {
  --callout-bg-color: var(--bg-color-error);
  --callout-border-color: var(--text-color-error);
  --callout-icon-path: url(/images/icons/error.svg);
}
