header {
  align-items: center;
  backdrop-filter: blur(var(--spacing-8));
  display: grid;
  gap: var(--gap-default);
  grid-template-columns: auto 1fr;
  padding: var(--spacing-3) var(--spacing-6);
  position: sticky;
  top: 0;
  z-index: 100;

  > a {
    color: var(--title-color);
    font-family: var(--title-font);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
  }
}

header a {
  display: flex;
}

header img {
  width: min(20rem, 60vw);
}

header nav {
  display: flex;
  gap: var(--spacing-3);
  justify-self: end;

  [data-netlify-identity-menu] {
    display: none;
  }

  .netlify-identity-menu {
    align-items: center;
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      padding: var(--spacing-2) var(--spacing-3);
    }
  }
}

nav-links {
  box-sizing: border-box;
  display: flex;
}

@media(max-width: 800px) {
  nav-links {
    background-color: var(--bg-color);
    border-right: var(--border-default);
    box-shadow: var(--box-shadow-medium);
    flex-direction: column;
    height: 100vh;
    left: calc(var(--doc-sidebar-width) * -1);
    max-width: calc(var(--doc-sidebar-width) - var(--spacing-14));
    padding: var(--spacing-10) var(--spacing-3);
    position: absolute;
    transition: all 0.4s ease-in-out;
    top: 0;
    width: var(--doc-sidebar-width);
    z-index: 100;
  }

  nav-links[data-toggle-active="true"] {
    left: 0;
  }
}

@media(max-width: 600px) {
  header input[type="search"] {
    display: none;
  }
}
