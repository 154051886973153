home-hero {
  background-image: radial-gradient(circle at 100% 150%, var(--bg-color) 25%, white 25%, white 29%, var(--bg-color) 29%, var(--bg-color) 36%, white 36%, white 40%, transparent 40%, transparent), radial-gradient(circle at 0 150%, var(--bg-color) 25%, white 25%, white 29%, var(--bg-color) 29%, var(--bg-color) 36%, white 36%, white 40%, transparent 40%, transparent), radial-gradient(circle at 50% 100%, white 10%, var(--bg-color) 10%, var(--bg-color) 23%, white 23%, white 30%, var(--bg-color) 30%, var(--bg-color) 43%, white 43%, white 50%, var(--bg-color) 50%, var(--bg-color) 63%, white 63%, white 70%, transparent 70%, transparent), radial-gradient(circle at 100% 50%, white 5%, var(--bg-color) 5%, var(--bg-color) 15%, white 15%, white 20%, var(--bg-color) 20%, var(--bg-color) 30%, white 30%, white 35%, var(--bg-color) 35%, var(--bg-color) 45%, white 45%, white 50%, transparent 50%, transparent), radial-gradient(circle at 0 50%, white 5%, var(--bg-color) 5%, var(--bg-color) 15%, white 15%, white 20%, var(--bg-color) 20%, var(--bg-color) 30%, white 30%, white 35%, var(--bg-color) 35%, var(--bg-color) 45%, white 45%, white 50%, transparent 50%, transparent);
  background-size: 60px 30px;
  display: grid;
  height: calc(100dvh - var(--spacing-40));
  gap: 0;
  grid-template-columns: 7fr 0 11fr;
  grid-template-rows: 4fr 4fr 1fr 1fr;
  padding: 0;
  position: relative;
  
  .baseline {
    display: flex;
    flex-direction: column;
    grid-column: 1 / 1;
    grid-row: 1 / 9;
    justify-content: center;
    padding-inline: 4rem 8rem;

    h1 {
      font-family: var(--title-font);
      font-size: clamp(0.74rem, 3rem, 5rem);
      font-weight: 600;
      margin: 0;
  
      em {
        color: var(--primary-color);
        font-style: normal;
      }
    }  

    p {
      font-size: 1.75rem;
    }
  }

  .ornament {
    align-items: center;
    color: var(--primary-color);
    display: flex;
    font-family: var(--title-font);
    font-size: clamp(4rem, 9rem, 15rem);
    font-weight: 600;
    justify-content: center;
    grid-column: 2/2;
    grid-row: 1/9;
    font-weight: bold;
    writing-mode: vertical-rl;
    z-index: 2;
  }

  .cover {
    grid-column: 3 / 5;
    grid-row: 1 / 9;
    position: relative;

    img {
      filter: sepia(1) hue-rotate(180deg);
      height: 100%;
      object-fit: cover;
      object-position: top;
      width: 100%;
    }
  }

  @media(max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 0 1fr;
    height: calc(100dvh - var(--spacing-18));

    .baseline {
      grid-column: 1 / 2;
      grid-row: 1 / 1;
      padding: var(--spacing-4) var(--spacing-8) var(--spacing-6);
      text-align: center;

      h1 {
        font-size: max(2rem, 6vw);
      }

      p {
        font-size: max(1rem, 4vw);
      }
    }
    
    .ornament {
      font-size: max(3rem, 12vw);
      grid-column: 1 / -1;
      grid-row: 2 / 2;
      writing-mode: revert;
    }

    .cover {
      grid-column: 1 / -1;
      grid-row: 2 / -1;
    }

    latest-news {
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      transform: revert;

      a {
        flex-grow: 1;
        height: auto;
      }
    }
  }
}
