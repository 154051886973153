.library {
  .books {
    display: grid;
    gap: var(--gap-medium);
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  book-card {
    border: var(--border-default);
    border-radius: var(--border-radius);
    display: flex;
    min-height: 125px;
    overflow: hidden;

      .cover {
        width: 30%;

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

    .content {
      padding: var(--spacing-3) var(--spacing-4);

      h2, p {
        margin: 0;
      }
    }
  }
}
