timeline-element {
  align-items: start;
  display: grid;
  grid-template-columns: clamp(100px, 25%, 170px) auto;
  gap: var(--spacing-10) clamp(var(--spacing-10), 15%, var(--spacing-34));

  .timeline-side {
    border-right: var(--border-default);
    box-sizing: border-box;
    height: 100%;
    padding-block: var(--spacing-8);
    padding-right: var(--spacing-10);

    time {
      display: flex;
      position: sticky;
      top: var(--spacing-24);

      &::after {
        background-color: var(--title-color);
        border-radius: 50%;
        content: "";
        display: block;
        height: var(--spacing-2);
        position: absolute;
        right: calc(var(--spacing-11-minus) - 0.5px);
        top: var(--spacing-2);
        width: var(--spacing-2);
      }
    }
  }

  .timeline-content {
    padding-block: var(--spacing-8);
    text-decoration: none;

    img {
      border-radius: var(--border-radius-xlarge);
      width: 100%;
    }

    h2 {
      font-size: 1.5rem;
      margin-block: 0 var(--spacing-2);
    }

    h2 > a {
      color: var(--title-color);
      text-decoration: none;
    }
  }

  @container main (width < 800px) {
    border-left: var(--border-default);
    gap: 0;
    grid-template-columns: 1fr;
    padding-left: var(--spacing-10);

    .timeline-side {
      border: 0;
      padding-bottom: 0;

      time {
        border: 0;
        padding: 0;
        position: relative;
        top: initial;
  
        &::after {
          right: calc(100% + var(--spacing-10) - var(--spacing-1));
          top: var(--spacing-2);
        }
      }
    }

    .timeline-content {
      padding-top: 0;
    }
  }
}
