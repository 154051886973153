latest-news {
  bottom: 0;
  display: flex;
  gap: var(--spacing-4);
  position: absolute;
  right: 0;
  transform: translateY(50%);

  a {
    background-color: var(--secondary-color);
    color: var(--white);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
    height: var(--spacing-34);
    padding: var(--spacing-5) var(--spacing-6);
    text-decoration: none;
    width: 15rem;
  
    h3 {
      color: var(--primary);
      font-size: 1.25rem;
      font-weight: 500;
      margin: 0;
    }
  } 
}
