season-pricing {
  background-color: var(--transparent-secondary-3);
  margin-top: var(--spacing-16);
  padding-block: var(--spacing-16);

  > div {
    display: flex;
    gap: clamp(var(--spacing-20), 10%, var(--spacing-40));
    justify-content: space-evenly;

    @media(max-width: 800px) {
      flex-direction: column;
    }
  }
}
