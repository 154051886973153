.club table {
  @media (max-width: 500px) {
    border: 0;
    
    tr {
      border: var(--border-default);
      display: flex;
      flex-direction: column;

      &:first-child {
        display: none;
      }
    }

    td, th {
      border: 0;
    }

    th {
      border-bottom: var(--border-default);
    }
  }
}
