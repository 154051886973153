/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
    font-family: "Montserrat";
    font-weight: 100;
    font-style: normal;
    src: url("../fonts/montserrat/Montserrat-Thin.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-Thin.woff") format("woff");
}

/** Montserrat Thin-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 100;
    font-style: italic;
    src: url("../fonts/montserrat/Montserrat-ThinItalic.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-ThinItalic.woff") format("woff");
}

/** Montserrat ExtraLight **/
@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    font-style: normal;
    src: url("../fonts/montserrat/Montserrat-ExtraLight.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-ExtraLight.woff") format("woff");
}

/** Montserrat ExtraLight-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    font-style: italic;
    src: url("../fonts/montserrat/Montserrat-ExtraLightItalic.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-ExtraLightItalic.woff") format("woff");
}

/** Montserrat Light **/
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: normal;
    src: url("../fonts/montserrat/Montserrat-Light.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-Light.woff") format("woff");
}

/** Montserrat Light-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: italic;
    src: url("../fonts/montserrat/Montserrat-LightItalic.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-LightItalic.woff") format("woff");
}

/** Montserrat Regular **/
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    src: url("../fonts/montserrat/Montserrat-Regular.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-Regular.woff") format("woff");
}

/** Montserrat Regular-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: italic;
    src: url("../fonts/montserrat/Montserrat-Italic.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-Italic.woff") format("woff");
}

/** Montserrat Medium **/
@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    font-style: normal;
    src: url("../fonts/montserrat/Montserrat-Medium.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-Medium.woff") format("woff");
}

/** Montserrat Medium-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    font-style: italic;
    src: url("../fonts/montserrat/Montserrat-MediumItalic.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-MediumItalic.woff") format("woff");
}

/** Montserrat SemiBold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: normal;
    src: url("../fonts/montserrat/Montserrat-SemiBold.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-SemiBold.woff") format("woff");
}

/** Montserrat SemiBold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: italic;
    src: url("../fonts/montserrat/Montserrat-SemiBoldItalic.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-SemiBoldItalic.woff") format("woff");
}

/** Montserrat Bold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: normal;
    src: url("../fonts/montserrat/Montserrat-Bold.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-Bold.woff") format("woff");
}

/** Montserrat Bold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: italic;
    src: url("../fonts/montserrat/Montserrat-BoldItalic.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-BoldItalic.woff") format("woff");
}

/** Montserrat ExtraBold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: normal;
    src: url("../fonts/montserrat/Montserrat-ExtraBold.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-ExtraBold.woff") format("woff");
}

/** Montserrat ExtraBold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: italic;
    src: url("../fonts/montserrat/Montserrat-ExtraBoldItalic.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-ExtraBoldItalic.woff") format("woff");
}

/** Montserrat Black **/
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: normal;
    src: url("../fonts/montserrat/Montserrat-Black.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-Black.woff") format("woff");
}

/** Montserrat Black-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: italic;
    src: url("../fonts/montserrat/Montserrat-BlackItalic.woff2") format("woff2"),
         url("../fonts/montserrat/Montserrat-BlackItalic.woff") format("woff");
}
