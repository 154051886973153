table {
  border: 1px solid var(--transparent-secondary-3);
  border-collapse: collapse;
  margin-block: 1rem;

  th, td {
    border: 1px solid var(--transparent-secondary-3);
    padding: var(--spacing-1) var(--spacing-2);
  }
}
