body {
  color: var(--secondary);
  display: flex;
  flex-direction: column;
  font-family: var(--text-font);
  grid-template-rows: auto 1fr auto;
  line-height: 1.5;
  margin: 0;
  min-height: 100vh;
  position: relative;
}

main {
  container: main / inline-size;
  flex-grow: 1;

  &.page {
    padding-bottom: var(--spacing-20);
  }
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr min(var(--page-content-width), calc(100% - var(--spacing-20))) 1fr;
  column-gap: var(--spacing-10);
  justify-content: center;
}

.wrapper > * {
  grid-column: 2;
}

.wrapper[data-wrapper-size="small"] {
  --page-content-width: 800px;
}

.full-bleed {
  grid-column: 1 / -1;
}

.section {
  column-gap: 4rem;
  display: flex;
}

.double-pane {
  --side-min-width: 100px;

  display: grid;
  grid-template-columns: max(var(--side-min-width), min(25%, 200px)) auto;
  gap: var(--spacing-10) clamp(var(--spacing-10), 15%, var(--spacing-34));

  aside {
    border-right: var(--border-default);
    box-sizing: border-box;
    height: 100%;
    padding-block: var(--spacing-14);
    padding-right: var(--spacing-10);
    width: 100%
  }
}

.timeline {
  margin: 0;
  padding: 0;
}

@container main (width < 800px) {
  .double-pane {
    gap: var(--gap-large);
    grid-template-columns: 1fr;
    padding-block: var(--spacing-10);

    aside {
      border: 0;
    }
  }
}
