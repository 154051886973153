dl {
  &:first-child {
    margin-top: 0;
  }

  dt {
    font-weight: var(--font-weight-semibold);
  }

  dd {
    margin: 0;

    + dt {
      margin-top: var(--spacing-4);
    }

    ul {
      padding-left: var(--spacing-4);
    }
  }
}
