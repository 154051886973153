.workshops main {
  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

.workshop {
  .content {
    display: flex;
    gap: var(--spacing-6);

    > * {
      flex-grow: 1;
    }

    .workshop-image {
      img {
        width: 100%;
      }
    }
  }

  hr {
    border-color: var(--primary);
    border-top: 0;
  }
}
